<template>
  <div>

    <div class="col-12">
      <div class="card mb-4">

        <div style="text-align: right; ">
            <b-button 
               variant="outline-primary"    
              @click="toggleFullLayout"
             size="sm"
            >
            <feather-icon
            icon="Maximize2Icon"
            size="12"
          />
          
          </b-button>
        </div>

        <div class="card-header">
          <h4 class="card-title">
            <span>{{RelName}}</span>
          </h4>
     
          <b-col v-if="showFiltro ? false : true ">
            <div style="float:right">
              <div class="DivBotaoLadoLado" >
              <b-button variant="danger" @click="$router.push('../Relatorios')">
                   <span>RETORNAR</span>
               </b-button>
              </div>
               
               <div class="DivBotaoLadoLado" >
               <b-button v-b-toggle="'collapse-filtro'" variant="primary" >
                 <span>FILTROS</span>
               </b-button>
               </div>
            </div>
          </b-col>

        </div>

        <b-collapse visible  id="collapse-filtro">
        <div class="card-body">
          
          <b-row>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Inicial:"
            >
            <b-form-datepicker
                 id="PeridoIni"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoIni"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Final:"
            >
            <b-form-datepicker
                 id="PeridoFin"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoFin"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>

             
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Volume:" >
                <multiselect
                    v-model="CodVol"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodVolOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  >
                  </multiselect>

              </b-form-group>
            </b-col>
     
 
          </b-row>

          <b-row>
            
            <b-col md="12" xl="9" class="mb-1">
             <b-form-group label="Produto:" >

                 <multiselect
                   v-model="CodProd"
                   placeholder="Selecione"
                   label="name"
                   track-by="code"
                   :options="CodProdOption"
                   :multiple="true"
                   :taggable="false"
                   :close-on-select="true"
                   :option-height="50"
                   :show-labels="false"
                 ></multiselect>

             </b-form-group>
           </b-col>

          </b-row>




        <b-row>
            


        <b-col
                md="6"
                xl="12"
                class="mb-1"
              >
              <div class="DivBotaoLadoLado" >
               <b-button
              variant="danger"
              type="button"
              @click="$router.push('../Relatorios')"
            >
              <span>RETORNAR</span>
            </b-button>
              </div>

              <div class="DivBotaoLadoLado" >
            <b-button v-b-toggle="'collapse-filtro'"
              variant="primary"
              type="submit"
              @click="ConsultarRelatorio"
            >
              <span>CONSULTAR</span>
            </b-button>
              </div>
              
          </b-col>


         </b-row>
         
        </div>
        </b-collapse>
        
      </div>
    </div>


      
    <div class="col-12" v-if="TabRelVendaProdutoPosition >= 0">
            <div class="card mb-4">
              <div class="card-body pb-60" >
                
                
              <div v-if="TabRelVendaProdutoPosition == 0">
                
                <div id="TabRelVendaProduto">

                <h4 class="fw-bolder mb-1">Detalhe</h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelVendaProduto"
                  :fields="visibleFields"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                  
                  
                >

                 <template #cell(JANEIRO)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(FEVEREIRO)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(MARCO)="row">
                      {{  TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(ABRIL)="row">
                      {{  TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(MAIO)="row">
                      {{  TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(JUNHO)="row">
                      {{  TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(JULHO)="row">
                      {{  TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(AGOSTO)="row">
                      {{  TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(SETEMBRO)="row">
                      {{  TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(OUTUBRO)="row">
                      {{  TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(NOVEMBRO)="row">
                      {{  TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(DEZEMBRO)="row">
                      {{  TextFormat(row.value,'money') }}
                 </template>  
                 
                 <template v-slot:custom-foot>
                  
                  <tr>
                    <td class="bg-secondary text-white" colspan="4">
                     Total
                    </td>

                    <td class="bg-secondary text-white" v-if="JAN" > 
                      {{ TextFormat(TabRelVendaProdutoTotal('JANEIRO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" v-if="FEV"  > 
                      {{ TextFormat(TabRelVendaProdutoTotal('FEVEREIRO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"  v-if="MAR" > 
                      {{ TextFormat(TabRelVendaProdutoTotal('MARCO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"  v-if="ABR" > 
                      {{ TextFormat(TabRelVendaProdutoTotal('ABRIL'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" v-if="MAI"> 
                      {{ TextFormat(TabRelVendaProdutoTotal('MAIO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" v-if="JUN"> 
                      {{ TextFormat(TabRelVendaProdutoTotal('JUNHO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" v-if="JUL"> 
                      {{ TextFormat(TabRelVendaProdutoTotal('JULHO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" v-if="AGO"> 
                      {{ TextFormat(TabRelVendaProdutoTotal('AGOSTO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" v-if="SET"> 
                      {{ TextFormat(TabRelVendaProdutoTotal('SETEMBRO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" v-if="OUT"> 
                      {{ TextFormat(TabRelVendaProdutoTotal('OUTUBRO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" v-if="NOV"> 
                      {{ TextFormat(TabRelVendaProdutoTotal('NOVEMBRO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" v-if="DEZ"> 
                      {{ TextFormat(TabRelVendaProdutoTotal('DEZEMBRO'),'money')}}
                    </td>
                   

                  </tr>
                </template>


                </b-table>
                </div>

                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelVendaProduto','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>
               

             </div>
            </div>
    </div>



  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'

import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
  BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    BFormDatepicker,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      showFiltro: true,
      FiltroRelatorio: [],
      RelName: '',
      RelRota: '',
      TipoConsulta: '',
      TabRelVendaProduto: [],
      TabRelVendaProdutofields: [],
      PeridoIni: '',
      PeridoFin: '',
      CodProd: [],
      CodProdOption: [],
      exportarParaSelected: 'Exporte',
      exportarParaOptions: [
        { value: 'Exporte', text: 'Exporte' },
        { value: 'XLSX', text: 'Excel' },
      ],
      TabRelVendaProdutoPosition: -1,
      JAN: false,
      FEV: false,
      MAR: false,
      ABR: false,
      MAI: false,
      JUN: false,
      JUL: false,
      AGO: false,
      SET: false,
      OUT: false,
      NOV: false,
      DEZ: false,
      CodVol: { "name": "PT", "code": "PT"} ,
      CodVolOption: [],
      CodVolSel: '',
    }
  },
  methods: {
    async MostraReport() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data != null) {
            this.RelName = response.data.descricaoRelatorio
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')

      if(notification === '') {
       
       let ParametroJson = ""
       let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Produto&ATIVO=S`
       await apiPortalSky.post(URLpost, ParametroJson).then(response => {
         if (response.status === 200) {
           if (response.data.length > 0) {
             this.CodProdOption = response.data
           } else {
             notification = ConexaoAPI.MsgFiltroSemRetorno()
             AlertaMsg.FormataMsg(notification)
           }
         }
       }).catch(error => {
         notification = ConexaoAPI.ValidaErro(error)
         AlertaMsg.FormataMsg(notification)
       })
       
       }

       if(notification === '') {
       
       let ParametroJson = ""
       let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=UnidadeMedida`
       await apiPortalSky.post(URLpost, ParametroJson).then(response => {
         if (response.status === 200) {
           if (response.data.length > 0) {
             this.CodVolOption = response.data
           } else {
             notification = ConexaoAPI.MsgFiltroSemRetorno()
             AlertaMsg.FormataMsg(notification)
           }
         }
       }).catch(error => {
         notification = ConexaoAPI.ValidaErro(error)
         AlertaMsg.FormataMsg(notification)
       })
       
       }



      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarRelatorio(OrigemConsulta) {
      Spinner.StarLoad()

      //Inicio Dados Base Relatorio
      this.showFiltro = false
      const usuario = this.$session.get('usuario')
      let notification = ""
      this.FiltroRelatorio = []
      //Fim Dados Base Relatorio

      //Inicio Limpa Dados Retorno
      this.TabRelVendaProduto = []
      
      // Fim Limpa Dados Retorno
      
      //Inicio Volta Todos Niveis para posição inicial
      this.TabRelVendaProdutoPosition = 0
      //Fim Volta Todos Niveis para posição inicial


      //Inicio Pega parametros de Parametros
       if(this.PeridoIni != "")
       {
         const Filtro = {
           parametro: 'DTI',
           valor: this.PeridoIni,
         }
         this.FiltroRelatorio.push(Filtro)
       }

       if(this.PeridoFin != "")
       {
         const Filtro = {
           parametro: 'DTF',
           valor: this.PeridoFin,
         }
         this.FiltroRelatorio.push(Filtro)
       }

   
       this.CodProd.forEach(Item => {
        const Filtro = {
          parametro: 'CODPROD',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })


      if(this.CodVol != null){
        if(Array.isArray(this.CodVol) == false)
        {
          const Filtro = {
            parametro: 'CODVOL',
            valor: this.CodVol.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }


      //Fim Pega parametros de Parametros
      

      let ParametroJson = ''
      let URLpost = ''
      

    if(notification === '') {
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelVendaProduto?CODUSU=${usuario.codusu}&Nivel=RelVendaProdutoSGI&TypeRDLCexport=`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabRelVendaProduto = response.data

            this.CustomFields()
             
           
            
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

    }



     if(notification === '') {
       Spinner.EndLoad()
     }

    },
    TabRelVendaProdutoTotal(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'JANEIRO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.JANEIRO }, 0.0)
        break
        case 'FEVEREIRO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.FEVEREIRO }, 0.0)
        break
        case 'MARCO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.MARCO }, 0.0)
        break
        case 'ABRIL':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.ABRIL }, 0.0)
        break
        case 'MAIO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.MAIO }, 0.0)
        break
        case 'JUNHO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.JUNHO }, 0.0)
        break
        case 'JULHO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.JULHO }, 0.0)
        break
        case 'AGOSTO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.AGOSTO }, 0.0)
        break
        case 'SETEMBRO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.SETEMBRO }, 0.0)
        break
        case 'OUTUBRO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.OUTUBRO }, 0.0)
        break
        case 'NOVEMBRO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.NOVEMBRO }, 0.0)
        break
        case 'DEZEMBRO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.DEZEMBRO }, 0.0)
        break

      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TabRelVendaProdutoTotalVisible(Coluna) {
      let Valor  = false

      switch (Coluna) {
        case 'JANEIRO':
          Valor = true
        break
        case 'FEVEREIRO':
          Valor = true
        break
        case 'MARCO':
          Valor = true
        break
        case 'ABRIL':
          Valor = true
        break
        case 'MAIO':
          Valor = true
        break
        case 'JUNHO':
          Valor = true
        break
        case 'JULHO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.JULHO }, 0.0)
        break
        case 'AGOSTO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.AGOSTO }, 0.0)
        break
        case 'SETEMBRO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.SETEMBRO }, 0.0)
        break
        case 'OUTUBRO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.OUTUBRO }, 0.0)
        break
        case 'NOVEMBRO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.NOVEMBRO }, 0.0)
        break
        case 'DEZEMBRO':
          Valor = this.TabRelVendaProduto.reduce((accum, item) => { return accum + item.DEZEMBRO }, 0.0)
        break

      }
      
      return Valor
      
    },


    CustomFields() {
      
      this.JAN = this.TabRelVendaProduto[0].JANEIRO != null ? true:false;
      this.FEV = this.TabRelVendaProduto[0].FEVEREIRO != null ? true:false;
      this.MAR = this.TabRelVendaProduto[0].MARCO != null ? true:false;
      this.ABR = this.TabRelVendaProduto[0].ABRIL != null ? true:false;
      this.MAI = this.TabRelVendaProduto[0].MAIO != null ? true:false;
      this.JUN = this.TabRelVendaProduto[0].JUNHO != null ? true:false;
      this.JUL = this.TabRelVendaProduto[0].JULHO != null ? true:false;
      this.AGO = this.TabRelVendaProduto[0].AGOSTO != null ? true:false;
      this.SET = this.TabRelVendaProduto[0].SETEMBRO != null ? true:false;
      this.OUT = this.TabRelVendaProduto[0].OUTUBRO != null ? true:false;
      this.NOV = this.TabRelVendaProduto[0].NOVEMBRO != null ? true:false;
      this.DEZ = this.TabRelVendaProduto[0].DEZEMBRO != null ? true:false;     
       
     
      this.TabRelVendaProdutofields = [
       { key: 'CODPROD', label: 'Cod.Sankya', visible: true,  sortable: true,tdClass: 'td100' },
       { key: 'REFERENCIA', label: 'Cod.Alternativo', visible: true, sortable: true,tdClass: 'td100' },
       { key: 'AD_CODESTRAPOLO', label: 'Cod.Estruturado', visible: true, sortable: true,tdClass: 'td100' },
       { key: 'DESCRPROD', label: 'Descrição', visible: true, sortable: true,tdClass: 'td300' }, 
       { key: 'JANEIRO', label: 'JAN', visible: this.JAN,tdClass: 'td100' },
       { key: 'FEVEREIRO', label: 'FEV', visible: this.FEV,tdClass: 'td100' },
       { key: 'MARCO', label: 'MAR', visible: this.MAR,tdClass: 'td100' },        
       { key: 'ABRIL', label: 'ABR', visible: this.ABR,tdClass: 'td100' },
       { key: 'MAIO', label: 'MAI ', visible: this.MAI,tdClass: 'td100' },
       { key: 'JUNHO', label: 'JUN', visible: this.JUN,tdClass: 'td100' },
       { key: 'JULHO', label: 'JUL', visible: this.JUL,tdClass: 'td100' },
       { key: 'AGOSTO', label: 'AGO', visible: this.AGO,tdClass: 'td100' },
       { key: 'SETEMBRO', label: 'SET', visible: this.SET,tdClass: 'td100' },
       { key: 'OUTUBRO', label: 'OUT', visible: this.OUT,tdClass: 'td100' },
       { key: 'NOVEMBRO', label: 'NOV', visible: this.NOV,tdClass: 'td100' },
       { key: 'DEZEMBRO', label: 'DEZ', visible: this.DEZ,tdClass: 'td100' },
      ]


      
      
    },
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    ExportarDoc (ElementToExport,DocName) {
      let DataExport = ""
      switch (ElementToExport) {
                case 'TabRelVendaProduto':
                DataExport = this.TabRelVendaProduto
                DocName = 'RelVendaProduto'
                break
      }    
      UtilVUE.ExportDoc(this.exportarParaSelected,DocName,ElementToExport,DataExport)
      this.exportarParaSelected = 'Exporte'
      return

    },
    toggleFullLayout() {
      UtilVUE.toggleFullLayout(this.$router, this.$route);
    }
  },
  computed: {
    visibleFields() {
        return this.TabRelVendaProdutofields.filter(field => field.visible)
      }
  },
  mounted() {
    if (this.$route.name != null) {
      this.RelRota = this.$route.name
      this.PeridoIni = new Date()
      this.PeridoFin = new Date()
      this.MostraReport()
      this.ConsultarOpcoes()
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
